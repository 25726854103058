.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 320px;
  min-height: 100vh;
  background-color: #252630;
  font-family: "Inter", sans-serif;
  color: #fff;
}

.main::before {
  width: 100%;
  height: 100%;
  transform: scale(1);
  position: fixed;
  background-image: url("assets/images/main-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  z-index: 0;
}

.card {
  margin: 3rem 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 34rem;
  z-index: 1;
  background-image: url("assets/images/card-bg.svg"),
    linear-gradient(60deg, #31333d 0%, #45485c 100%);
  background-repeat: repeat;
  background-size: 512px, cover;
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0rem 0rem 6rem 0rem rgb(0 0 0 / 20%);
  max-height: 90vh;
}

@media screen and (max-width: 800px) {
  .card {
    width: 90vw;
    max-height: 100%;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  gap: 1rem;
  background-image: url("assets/images/card-header-bg1.svg"),
    linear-gradient(
      45deg,
      rgba(48, 40, 71, 0.702) 0%,
      rgba(255, 99, 137, 0.639) 100%
    ),
    url("assets/images/card-header-bg2.svg");
  background-size: 512px, cover, cover;
  background-position: center, 0% 0%, center;
  background-repeat: repeat, repeat, repeat;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.footer {
  padding: 3rem;
  background-image: url("assets/images/card-header-bg1.svg"),
    linear-gradient(
      45deg,
      rgba(48, 40, 71, 0.702) 0%,
      rgba(255, 99, 137, 0.639) 100%
    ),
    url("assets/images/card-header-bg2.svg");
  background-size: 512px, cover, cover;
  background-position: center, 0% 0%, center;
  background-repeat: repeat, repeat, repeat;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.footer .link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.125rem;
  border: 3px solid rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  font-size: 1.25em;
  vertical-align: center;
  gap: 1rem;
  font-weight: 700;
}

.divider {
  position: relative;
  padding: 0;
  border: 0;
  width: 100%;
}

.divider::before {
  width: 100%;
  display: inline-block;
  border-top: 2px solid rgb(255, 255, 255, 0.02);
  content: "";
}
